@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

:root {
  --primary-blue: #01a2cc;
  --secondary-blue: #28b6da;
  --dark-blue: #00486d;
  --primary-orange: #ff9000;
  --secondary-orange: #ffaa3b;
  --button-orange: #f44336;
  --confirm-green: #3bb54a;
  --deny-red: #ff0033;
  --dark: #000000;
  --light: #ffffff;
  --whitesmoke: #f0f2f5;
  --grey-text: #4a4949;
  --size: 16px;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
}

* {
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}

body {
  padding: 0;
  margin: 0;
}

/* ############ Login Page ############*/

.login-page {
  height: 100vh;
}

.trippie-air-logo-login-page {
  width: 396px;
}

.login-box {
  align-items: center;
  background: white;
  border: none;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 8px 16px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  margin: 40px 0 0;
  padding: 15px 0 28px;
  width: 396px;
}

.login-form {
  padding: 10px;
}

.login-form input[type='text'],
.login-form input[type='password'] {
  width: 100%;
  padding: 12px;
  border: 1px solid #bdbdbd;
  border-radius: 4px;
  box-sizing: border-box;
  margin-bottom: 10px;
  resize: vertical;
  font-size: 16px;
}

::-webkit-input-placeholder {
  font-style: italic;
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
}

::-moz-placeholder {
  font-style: italic;
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
}

:-ms-input-placeholder {
  font-style: italic;
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
}

:-moz-placeholder {
  font-style: italic;
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
}

.login-form input::-webkit-input-placeholder {
  color: #bdbdbd;
}

.login-form input::-moz-placeholder {
  color: #bdbdbd;
}

.login-form input:-ms-input-placeholder {
  color: #bdbdbd;
}

.login-form input:-moz-placeholder {
  color: #bdbdbd;
}

.login-form button {
  width: 100%;
  padding: 10px;
  border: none;
  font-size: 20px;
  font-weight: bold;
  background: var(--dark-blue);
  color: white;
  cursor: pointer;
  transition: background 0.3s ease-in-out;
}

.login-form button:hover {
  background: var(--primary-blue);
}

.login-message {
  padding: 0 0 25px;
  text-align: center;
  font-weight: 600;
}

.test-class {
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

/* ############ Dashboard Navigation Classes ############*/
/* ############ Top Navigation Classes ############*/

.menu-item-text {
  transition: display 0.3s ease-in-out;
}

/* Menu Burger */

.menu-btn {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  z-index: 999;
  margin-right: 13px;
}

.menu-btn-burger {
  width: 20px;
  height: 3px;
  background: var(--dark-blue);
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
}

.menu-btn-burger::before,
.menu-btn-burger::after {
  content: '';
  position: absolute;
  width: 20px;
  height: 3px;
  background: var(--dark-blue);
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
}

.menu-btn-burger::before {
  transform: translateY(-6px);
}

.menu-btn-burger::after {
  transform: translateY(6px);
}

/* Animation for Burger*/

.menu-btn.open .menu-btn-burger {
  transform: translateX(-20px);
  background: transparent;
}

.menu-btn.open .menu-btn-burger::before {
  transform: rotate(45deg) translate(12.5px, -12.5px);
}
.menu-btn.open .menu-btn-burger::after {
  transform: rotate(-45deg) translate(12.5px, 12.5px);
}

.main-nav {
  position: relative;
  height: 66px;
  display: flex;
  align-items: center;
  padding: 0.5rem 0.5rem;
  box-shadow: 0 0px 8px rgba(0, 0, 0, 0.1);
  z-index: 100;
  background: white;
  width: 100%;
}

/* ############ Sidebar Navigation Classes ############*/
.wrapper {
  position: relative;
  padding-left: 280px;
  transition: padding 0.3s ease-in-out;
  background-color: #f4f6fa;
  min-height: 100vh;
}

.main-sidebar {
  position: fixed;
  top: 0;
  left: 0;
  display: block;
  width: 280px;
  color: white;
  height: 100%;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22) !important;
  transition: width 0.3s ease-in-out;
  z-index: 99;
  overflow-y: auto;
  overflow-x: hidden;
}

.main-sidebar::-webkit-scrollbar {
  width: 6px;
}

.main-sidebar::-webkit-scrollbar-track {
  background-color: #696d7a;
}

.main-sidebar::-webkit-scrollbar-thumb {
  background: #575b66;
  border-radius: 0px;
  transition: all 0.2s ease-in-out;
}

.main-sidebar::-webkit-scrollbar-thumb:hover {
  background: var(--primary-blue);
}

.dark-bg {
  background: #343a40;
}

.logo-link {
  display: flex;
  font-size: 1.25rem;
  line-height: 1.5;
  padding: 0.8125rem 0.5rem;
  transition: width 0.3s ease-in-out;
  white-space: nowrap;
  justify-content: center;
}

.logo-img {
  line-height: 0.8;
  max-height: 40px;
}

.sidebar {
  overflow: none;
  /* padding: 0 0.5rem; */
}

ul {
  padding-left: 20px !important;
  margin-top: 5px !important;
  list-style: none;
}

.nav-item,
.nav-header {
  height: 40px;
  border-radius: 5px;
  margin-bottom: 3px;
  background: #343a40;
}

.nav-link:hover {
  background: #474c52;
}

.nav-header {
  padding: 10px 0 10px 0;
  border-top: 1px solid rgba(204, 204, 204, 1);
  border-radius: 0;
  cursor: default;
  font-weight: 600;
}

.nav-item .nav-link {
  list-style: none;
  text-decoration: none;
  color: white;
  width: 100%;
  height: 100%;
}

.notification-bell {
  list-style: none;
  text-decoration: none;
}

.nav-link {
  display: block;
  position: relative;
  height: 40px;
  line-height: 40px;
  white-space: nowrap;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 4px;
}

.nav-link i {
  font-size: 18px;
  padding: 0 10px;
  width: 45px;
  text-align: center;
}

.user-info-navigation {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 60%;
}

.notification-bell {
  padding: 10px;
  font-size: 24px;
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.1s ease-in-out;
  color: #3c3c3c;
}

.notification-bell-icon {
  position: absolute;
  /* display: none; */
  top: 10px;
  display: flex !important;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: var(--deny-red);
  transform: translate(-10px, -0px);
  font-size: 11px;
  text-align: center;
  align-items: center !important;
  color: white;
  justify-content: center;
}

.notification-bell:hover {
  opacity: 1;
}

.user-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  font-size: 16px;
  margin-left: 10px;
  cursor: pointer;
  color: #4a4949;
}

.user-info img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: 3px solid var(--primary-blue);
  margin-right: 10px;
}

.user-info:hover {
  color: #1c1c1c;
}

.user-dropdown-content {
  position: absolute;
  height: 0;
  right: 0px;
  top: 66px;
  background-color: #ffffff;
  min-width: 240px;
  box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.2);
  z-index: 20;
  display: block;
  box-sizing: border-box;
  transition: all 0.3s ease-in-out;
}

.user-dropdown-content .drop-down-item {
  display: none;
  opacity: 0;
  position: relative;
  transition: all 0.1s ease-in-out;
}

.user-dropdown-link {
  text-decoration: none;
  display: block;
  margin: 15px;
  color: #4a4949;
}

.user-dropdown-link:hover {
  color: #1c1c1c;
}

.user-dropdown-link::before {
  content: '';
  position: absolute;
  width: 0%;
  height: 2px;
  background: var(--primary-blue);
  transform: translateY(25px);
  transition: width 0.2s ease-in-out;
}

.user-dropdown-link:hover::before {
  width: 100%;
}

.log-out-button {
  margin: 10px;
  padding: 10px;
  color: white;
  font-weight: 500;
  border-radius: 3px;
  background: var(--primary-blue);
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.log-out-button:hover {
  transform: scale(1.02);
}

.log-out-button:active {
  transform: scale(1);
}

#downChevronUser {
  margin-left: 10px;
  transition: transform 0.2s ease-in-out;
}

.side-menu-expand-chevron i {
  font-size: 14px;
  margin-right: 10px;
  transform: rotate(0deg);
  position: absolute;
  right: -10px;
  top: calc(50% - 7px);
  transition: transform 0.2s ease-in-out;
}

/* Navigation Treeview Classes*/

.nav-treeview-open {
  /* margin-top: 3px; */
  /* max-height: 2000px; */
  padding-left: 10px !important;
  /* transition: all 0.4s ease-in-out; */
}

.nav-treeview-closed {
  max-height: 0;
  overflow: hidden;
  transition: all 0.4s ease-in-out;
}

.nav-treeview-open {
  display: block;
  height: auto;
}

.special-hight-dropdown-link {
  padding-top: 11px;
  padding-bottom: 11px;
}

.has-treeview {
  height: auto;
}

/* Navigation Treeview Classes Ends*/
/* ############ Navigation Classes END ############ */

/* ############ Verify Trip Classes ############*/
.trip-list {
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05) 0 1px 2px rgba(0, 0, 0, 0.05);
  padding: 15px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  width: 100%;
}

.trip-list-table,
.notifications-list-table,
.report-list-table {
  width: 100%;
}

.trip-list-table-head,
.notifications-list-header,
.report-list-header {
  display: flex;
  background: rgb(103, 103, 103);
  text-align: center;
}

.trip-list-table-head-title,
.notifications-list-header-title,
.report-list-header-title {
  font-weight: 500;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.trip-list-table-head-title,
.trip-list-item-entry {
  width: 16.667%;
  padding: 10px;
}

.trip-list-item-line,
.faq-list-item {
  display: flex;
  flex-direction: row;
  transition: background 0.2s ease-in-out;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.trip-list-item-line:hover,
.notification-link:hover,
.request-list-link-item:hover,
.report-link:hover,
.faq-list-item:hover {
  background: #dfdfdf;
  cursor: pointer;
}

.trip-list-item-entry {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  overflow-wrap: break-word;
  hyphens: auto;
  word-break: break-all;
  height: 60px;
  padding: 3px;
}

.faq-list-item-entry {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  max-height: 3000px;
  padding: 5px;
}

.trip-list-item-entry img {
  max-height: 100%;
  max-width: 100%;
}

.trip-list-item-entry i:hover {
  opacity: 0.8;
}

.trip-list-item-entry i:active {
  transform: scale(0.9);
}

/* ############ Verify Trip Classes end ############*/

/* ############ Trips Details ############ */
.trip-details-background {
  position: relative;
  width: 100%;
}

.trip-details-container {
  padding: 30px;
}

.data-container,
.customer-info {
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05) 0 1px 2px rgba(0, 0, 0, 0.05);
}

.box-title-container {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.box-title {
  font-size: 18px;
  font-weight: 600;
  margin: 15px;
}

.data-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 15px;
}

.trip-data,
.modal-trip-data-image {
  width: 100%;
  background: #d3d1d2;
  margin-right: 15px;
  box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.2) !important;
  max-width: 50%;
}

.modal-trip-data-image {
  margin: 0px;
}

#tripFlightDetails,
#tripBagDetails,
#tripTravellerDetails,
#tripItineraryDetails {
  display: flex;
  position: relative;
  flex-direction: row;
  margin: 15px;
  background: white;
  border-radius: 6px;
  padding: 15px;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1), 0 8px 6px rgba(0, 0, 0, 0.09) !important;
}

#tripFlightDetails .depart-arrive-details,
#tripFlightDetails .drop-off-details {
  flex: 1 1 auto;
  display: flex;
}

#tripFlightDetails .depart-arrive-details {
  flex-direction: column;
  width: 100%;
}

#tripFlightDetails .drop-off-details {
  align-items: center;
  justify-content: center;
}

.depart-arrive-details {
  width: 50%;
}

.depart-details,
.arrive-details,
.traveller-details {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.depart-details {
  margin-bottom: 10px;
}

.details-img {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
}

.details-img i {
  font-size: 50px;
  padding: 10px;
  color: var(--primary-blue);
}

.bold-title {
  font-weight: 500 !important;
}

.info {
  width: 90%;
}

.info-content,
.user-data-content {
  position: relative;
  padding: 2px;
  font-weight: lighter;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.drop-off-details {
  width: 50%;
}

.drop-off-container {
  font-weight: lighter;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.drop-off-img {
  width: 60px;
}

.traveller-details-container {
  width: 100%;
}

#tripItineraryDetails {
  overflow: auto;
  height: 572px;
  display: block;
}
#tripItineraryDetails img {
  width: 100%;
  height: auto;
  border-radius: 6px;
}

.details-title {
  display: flex;
  width: 100%;
  justify-content: center;
}

.title-content {
  font-weight: 500;
}

.traveller-details-container {
  width: 100%;
}

.baggage-details-header {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid var(--primary-blue);
  padding-bottom: 10px;
  padding-top: 10px;
}

.header-bag-type,
.header-bag-dimensions,
.header-available-weight {
  display: flex;
  flex-direction: column;
  width: 33.33%;
}

.header-bag-type i,
.header-bag-dimensions i,
.header-available-weight i {
  font-size: 28px;
}

.authorisation-controls {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.auth-50-percent {
  width: 50%;
}

.auth-btn-box {
  margin: 0 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.confirmation-auth-checkbox {
  appearance: none;
  border: 1px solid var(--dark-blue);
  height: 25px;
  width: 25px;
  border-radius: 50%;
  position: relative;
}

.confirmation-auth-checkbox:active,
.confirmation-auth-checkbox:checked:active {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px 1px 3px rgba(0, 0, 0, 0.1);
}

.confirmation-auth-checkbox:checked {
  background-color: var(--primary-blue);
  border: 1px solid var(--dark-blue);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05),
    inset 15px 10px -12px rgba(255, 255, 255, 0.1);
  color: #99a1a7;
}

.confirmation-auth-checkbox:checked:after {
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  content: '\f00c';
  font-size: 14px;
  position: absolute;
  top: 5px;
  left: 5px;
  color: #ffffff;
}

.auth-button {
  width: 50px;
  height: 50px;
  background: var(--confirm-green);
  color: white;
  padding: 8px;
  border-radius: 50%;
  border: none;
  font-size: 18px;
  transition: all 0.1s ease-in-out;
}

.auth-button i {
  font-size: 24px;
}

.auth-button:hover {
  cursor: pointer;
  opacity: 0.8;
}

.auth-button:active {
  transform: scale(0.98);
  background: var(--dark-blue);
}

.deny-trip {
  background: var(--deny-red);
}

.confirmation-container {
  margin: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  gap: 10px;
}

.confirmation-label-container {
  margin-right: 20px;
}

/* ############ Trips Details End ############ */
/* ############ Active Trip Page ############ */

.active-trip-card-container {
  width: 50%;
  padding: 5px;
  transition: all 0.3s ease-in-out;
}

.active-trip-card-container:hover,
.user-profile-card:hover {
  transform: scale(1.02);
}

.active-trip-list,
.user-profile-list,
.report-list-content,
.create-user-list {
  padding: 0px 15px 15px 15px;
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.user-profile-list,
.report-list-content {
  padding: 15px;
}

.active-trip-container,
.user-profile-container {
  position: relative;
  background: #d3d1d2;
  padding: 15px;
  box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.2) !important;
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  box-sizing: border-box;
}

.active-trip-card {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  background: white;
  border-radius: 6px;
  padding: 15px;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1), 0 8px 6px rgba(0, 0, 0, 0.09) !important;
  width: 100%;
}

.active-trip-card .traveller-details-container,
.report-info-card .traveller-details-container {
  border-top: 1px solid var(--primary-blue);
  padding-top: 10px;
}

.trip-details-active-trips {
  width: 70%;
  padding-bottom: 10px;
}
.trip-details-active-trips:has(.drop-info) {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.trip-details-active-trips:has(.drop-info) .info {
  display: block;
}
.trip-details-active-trips .drop-info {
  flex: 0 0 75%;
}
.trip-details-active-trips img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.change-trip-display-container {
  display: flex;
  flex-direction: row;
  padding-bottom: 15px;
  justify-content: space-between;
  gap: 50px;
  padding-top: 10px;
}

.change-buttons {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-top: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
  flex-direction: row;
}

.change-trip-display-button {
  padding: 15px;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease-in-out;
  color: #505050;
}

.change-trip-display-button:nth-last-of-type(1) {
  border: none;
}

.request-chevron {
  float: right;
}

.change-trip-display-button:hover {
  background: var(--primary-blue);
  color: var(--light);
}

/* ############ Active Trip Page End ############ */
/* ############ Profile Page End ############ */

.profile-content,
.notifications-content {
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05) 0 1px 2px rgba(0, 0, 0, 0.05);
  padding: 15px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
}

/* ############ Profile Page End ############ */
/* ############ User Management Page ############ */

.user-profile-card {
  padding: 5px;
  width: 50%;
  transition: all 0.3s ease-in-out;
}

.user-profile-card-content {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 15px;
  background: white;
  border-radius: 6px;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1), 0 8px 6px rgba(0, 0, 0, 0.09) !important;
}

/* Customer Details Page */

.customer-data-container {
  width: 100%;
  margin-bottom: 5px;
}

.customer-data-container-50 {
  width: 50%;
}

.left-50-container {
  margin-right: 5px;
}

.display-customer-details {
  width: 100%;
}

.edit-customer-details {
  padding-left: 5px;
}

.customer-info.traveller-details {
  display: flex;
  flex-direction: column;
}

.customer-info.edit-data {
  height: 100%;
}

.customer-info-title {
  padding: 10px;
  width: 100%;
  text-align: center;
  font-weight: 500;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.edit-user-data,
.traveller-data,
.user-data {
  padding: 5px;
}

.user-data {
  display: flex;
  flex-direction: row;
  position: relative;
  min-height: 150px;
}

.user-profile-image {
  height: 70px;
  width: 70px;
  border-radius: 50%;
  border: 3px solid var(--primary-blue);
}

.large-profile {
  height: 125px;
  width: 125px;
  margin-bottom: 10px;
}

.user-data-container {
  width: 100%;
}

.user-data-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.user-data-content-info.bold-title {
  width: 30%;
}

.user-data-content-info {
  width: 70%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.user-data-content-info-input,
.filter-option-date-picker {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  resize: vertical;
  font-size: 14px;
  font-weight: normal;
  color: #666666;
  resize: none;
}

.addressFields {
  padding: 2px 0;
}

.user-data-content-info textarea {
  resize: none;
}

.user-data-container .details-img {
  width: 100%;
}

.traveller-data {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 30px 0;
}

.account-status,
.transfer-status,
.payouts-status {
  width: 33.33%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.action-buttons-container {
  display: flex;
  justify-content: flex-end;
  padding: 5px;
  width: 100%;
  gap: 10px;
}

.notification-filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.user-action-button {
  border: none;
  cursor: pointer;
  background: var(--dark-blue);
  color: white;
  text-align: center;
  padding: 10px 12px;
  border-radius: 6px;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.35);
  transition: all 0.2s ease-in-out;
  font-size: 14px;
  min-width: 100px;
}

.user-action-button:hover {
  transform: translateY(-2px);
  box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.5);
  background: var(--primary-blue);
  border: none;
}

.user-action-button:active {
  transform: translateY(1px);
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.35);
}

.save-user-btn:hover {
  background: var(--primary-blue);
}

.ban-user-btn {
  margin-right: 15px;
  background: var(--primary-orange);
}

.ban-user-btn:hover {
  background: var(--secondary-orange);
}

.ban-user-btn.banned {
  opacity: 0.5;
  cursor: not-allowed;
}

.request-list {
  height: auto;
  max-height: 700px;
  overflow-y: scroll;
}

.request-list-dropdown {
  max-height: 0;
  transition: all 0.25s ease-in-out;
  overflow: hidden;
}

.request-list-dropdown.request-list-open {
  max-height: 2000px;
  transition: max-height 1s ease-in-out;
  margin: 15px 0;
}

.request-list-link {
  color: rgb(80, 80, 80);
  text-decoration: none;
}

.request-list-link-item,
.request-list-link-item-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  margin: 0 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  transition: background 0.2s ease-in-out;
}

.request-list-link-item-header {
  background: rgb(103, 103, 103);
  color: white;
  font-weight: 500;
}

.request-list-link-item-header-title.name-title,
.request-list-link-item-header-title.createdate-title {
  width: 40%;
  padding: 0 10px;
}

.request-list-link-item-header-title.status-title {
  width: 30%;
  padding: 0 10px;
}

/* ############ User Management Page End ############ */
/* ############ Notifications Page  ############ */

.notifications-content,
.notifications-list-table {
  flex-direction: column;
}

.notifications-list-table {
  display: flex;
}

.fa-envelope {
  color: var(--primary-orange);
}

.notifications-list-header,
.report-list-header {
  padding: 10px 0;
}

.notification-link,
.report-link,
.payment-link {
  text-decoration: none;
  color: #5b5b5b;
  display: flex;
  flex-direction: row;
  height: 60px;
  transition: background 0.2s ease-in-out;
}

.notif-10-width {
  width: 10%;
}

.notif-40-width {
  width: 40%;
}

.notification-item-box,
.reports-item-box {
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.notification-item-box i,
.reports-item-box i {
  font-size: 24px;
}

.flex-centered {
  justify-content: center;
}

.fa-flag {
  color: var(--button-orange);
}

/* ############ Notifications Page End ############ */
/* ############ Request Page  ############ */

.request-content {
}

.request-item-info-card,
.report-info-card,
.edit-faq-container {
  position: relative;
  border: 2px solid #fff;
  box-shadow: 0px 0px 10px 0 rgba(0, 0, 0, 0.3);
  padding: 15px 15px;
  width: 100%;
  margin: 0px 0 20px 0;
  border-radius: 6px;
  background: white;
}

.edit-faq-container {
  padding-bottom: 80px;
}

.request-item-info-card,
.report-info-card {
  display: flex;
  flex-direction: row;
}

.item-info-image {
  width: 100%;
}

.item-info-content-container {
  margin: 0 15px;
}

.item-info-content-title {
  font-size: 24px;
}

.item-info-creation-date {
  text-align: right;
}

.address-details-container,
.request-item-info-card {
  color: #4a4949;
}

.address-details-address-header {
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 15px;
}

.address-details-address {
  font-size: 16px;
  font-weight: 300;
}

.pickup-dropoff-container {
  display: flex;
  flex-direction: row;
}

.margin7left {
  margin-left: 7px;
}

.margin7right {
  margin-right: 7px;
}

.pickup-dropoff-images-container {
  display: flex;
  flex-direction: row;
}

.pickup-delivery-image-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pickup-delivery-image {
  max-height: 400px;
  border-radius: 6px;
  box-shadow: 0 3px 5px 2px rgba(0, 0, 0, 0.24);
}

/* ############ Request Page End ############ */
/* ############ Report Page ############ */

/* Report List*/

.report-filter-container {
  border-radius: 6px;

  margin-top: -25px;
  margin-bottom: 15px;
}

.filter-listing-type {
  display: flex;
  flex-direction: row;
  border-radius: 6px;
}

.filter-listing-type-btn {
  background: white;
  padding: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  text-align: center;
  font-weight: bold;
  font-size: 15px;
  color: var(--primary-blue);
  box-shadow: 0 2px 5px 1px rgba(0, 0, 0, 0.1);
}

.filter-listing-type-btn:hover {
  background: rgb(236, 236, 236);
}

.filter-listing-type-btn i {
  font-size: 18px;
  padding-right: 5px;
}

.filter-listing-type-btn.active {
  color: white;
  background: var(--primary-blue);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.border-top-left {
  border-radius: 6px;
}

.border-top-right {
  border-radius: 6px;
}

.filter-listing-type-btn.active:hover {
  opacity: 0.9;
}

.filter-listing-type-btn:nth-of-type(1) {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.filter-options-container {
  padding: 10px;
}

.report-info-card {
  color: #505050;
  display: flex;
  flex-direction: column;
}

.report-info-card.trip-details {
  transition: transform 0.2s ease-in-out;
}

.report-info-card.trip-details:hover {
  transform: scale(1.005);
}

.report-trip-details-active-trips {
  width: 100%;
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;
}

.report-trip-details-active-trips .depart-details,
.report-trip-details-active-trips .arrive-details {
  width: 50%;
}

.report-header {
  margin: 0 0 10px 0;
  font-size: 20px;
}

.reporter-user-profile-card {
  padding-bottom: 10px;
  transition: background 0.2s ease-in-out;
}

.reporter-user-profile-card:hover {
  background: #e8e8e8;
}

.report-details-card {
  padding-bottom: 10px;
}

.report-items {
  display: flex;
  flex-direction: row;
}

.report-items-title {
  font-weight: 500;
}

.report-items-description {
  font-weight: 300;
}

.report-items {
  padding-bottom: 5px;
}

.filter-options-container {
  padding: 20px;
  color: var(--grey-text);
  font-size: 15px;
  display: flex;
}

.filter-option-title {
  font-weight: 500;
}

.filter-option-date {
  position: relative;
}

.filter-option-date-picker-label {
  position: absolute;
  left: 5px;
  top: 2px;
  font-size: 12px;
  background: white;
  padding: 0 3px;
}

.filter-option-date-picker {
  margin-top: 8px;
  padding: 8px;
}

.filter-option {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

/* ############ Report Page End ############ */
/* ############ Other Settings Page Starts ############ */

.edit-legal-desc {
  margin: 20px 0;
}

/* ############ Other Settings Page Ends ############ */
/* ############ Financials Page ############ */

.profit-box {
  display: flex;
  flex-direction: row;
  margin-bottom: 25px;
}

.total-profit,
.total-transfers {
  background: var(--primary-blue);
  color: white;
  padding: 20px;
  border-radius: 6px;
  box-shadow: 0 4px 8px 2px rgb(194, 194, 194);
  display: flex;
  flex-grow: row;
  justify-content: space-between;
  align-items: center;
}
.total-transfers {
  background: var(--secondary-orange);
}

.box-info-number {
  font-size: 36px;
}

.box-info-desc {
  font-weight: lighter;
}

.box-img i {
  opacity: 0.25;
  font-size: 100px;
  padding: 30px;
  color: black;
}

.receipt-link {
  color: var(--primary-blue);
}

.receipt-link:hover {
  color: var(--secondary-blue);
}

.receipt-link:visited {
  color: var(--dark-blue);
}

/* ############ Financials Page Ends ############ */
/* ############ Global Classes ############*/

.flex-column {
  display: flex;
  flex-direction: column;
}

.editor-container {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin: 10px 0 0 0;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

/* Paginate Buttons Classes */
.pagination-container {
  margin: 5px;
  margin-top: 15px;
  padding: 8px;
  border-radius: 8px;
  width: 100%;
  display: flex;
  justify-content: center;
  background: white;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1), 0 8px 6px rgba(0, 0, 0, 0.09) !important;
}

.no-shadow {
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0), 0 8px 6px rgba(0, 0, 0, 0) !important;
}

.pagination {
  display: flex;
}

.pagination li {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 0 5px;
}

.pagination li a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  color: var(--dark);
  padding: 8px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
}

.pagination li:hover a {
  transform: scale(1.05);
}

.pagination li:active a {
  animation: buttonBounce;
  animation-duration: 0.2s;
}

@keyframes buttonBounce {
  0% {
    transform: scale(1.05);
  }
  10% {
    transform: scale(0.98);
  }
  40% {
    transform: scale(1.02);
  }
  100% {
    transform: scale(1);
  }
}

.pagination .active a {
  background: var(--primary-blue);
  border-color: var(--secondary-blue);
  color: var(--light);
  font-weight: 500;
}

.active {
  background: var(--primary-blue);
  border-color: var(--secondary-blue);
  color: var(--light);
  font-weight: 500;
}

/* Paginate Buttons Classes Ends */
/* Shimmer loading Classes */

.w80 {
  width: 80%;
}
.shimmer-card {
  border: 2px solid #fff;
  box-shadow: 0px 0px 10px 0 #a9a9a9;
  padding: 30px 40px;
  width: 100%;
  margin: 0px 0 20px 0;
  border-radius: 6px;
  background: white;
}
.shimmer-profile-pic {
  height: 65px;
  width: 65px;
}
.shimmer-content {
  height: 10px;
  background: #777;
  margin-top: 20px;
  border-radius: 6px;
}
.shimmer-wrapper {
  width: 0px;
  animation: fullView 0.5s forwards linear;
}

@keyframes fullView {
  100% {
    width: 100%;
  }
}

.animate {
  animation: shimmer 2.5s infinite;
  background: linear-gradient(to right, #dbdbdb 4%, #9d9d9d 25%, #dbdbdd 36%);
  background-size: 2500px 100%;
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

/* Shimmer loading classes end */
/* Spinner Classes */
.loading-spinner-background {
  position: absolute;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.1);
  z-index: 100;
}

.this-that {
  background: white;
  position: absolute;
  height: 100px;
  width: 100px;
  left: calc(50% - 50px);
  top: calc(50% - 50px);
  overflow: hidden;
  border-radius: 12px;
}

.spinner-background {
  display: none;
}

.spinner-background,
.modal-ban-user-background {
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  overflow-y: hidden;
  background-color: rgba(0, 0, 0, 0.6);
}

.spinner.loading {
  padding: 50px;
  text-align: center;
}

.spinner.loading:before {
  box-sizing: border-box;
  content: '';
  height: 60px;
  width: 60px;
  position: absolute;
  left: calc(50% - 30px);
  top: calc(50% - 40px);
  border-width: 5px;
  border-style: solid;
  border-bottom-color: #ebebeb;
  border-left-color: var(--primary-blue);
  border-top-color: var(--primary-blue);
  border-right-color: var(--primary-blue);
  border-radius: 100%;
  animation: rotation 0.9s infinite linear;
}

.spinner.loading:after {
  content: 'Loading...';
  position: absolute;
  width: 100%;
  left: calc(50% - 50px);
  top: calc(75%);
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

/* Progression Bar Starts*/
.progression-bar-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.progress-card {
  position: relative;
  z-index: 0;
  background-color: white;
  padding: 20px 0;
  border-radius: 10px;
  text-align: center;
  width: 100%;
}

#progressbar {
  overflow: hidden;
  color: #455a64;
  padding-left: 0px;
  margin: 10px 0;
}

#progressbar li {
  list-style-type: none;
  font-size: 13px;
  width: 14%;
  float: left;
  position: relative;
  font-weight: 400;
  font-size: 12px;
  width: 14.285%;
}

#progressbar .step0:before {
  font-family: FontAwesome;
  content: '\f10c';
  color: #fff;
}

#progressbar li:before {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  background: #c9c9c9;
  border-radius: 50%;
  margin: auto;
  padding: 0px;
}

#progressbar li:after {
  content: '';
  width: 100%;
  height: 12px;
  background: #c9c9c9;
  position: absolute;
  left: 0;
  top: 16px;
  z-index: -1;
}

#progressbar li:last-child:after {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  position: absolute;
  left: -50%;
}

#progressbar li:nth-child(5):after,
#progressbar li:nth-child(6):after {
  left: -50%;
}

#progressbar li:first-child:after {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  position: absolute;
  left: 50%;
}

#progressbar li:last-child:after {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

#progressbar li:first-child:after {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

#progressbar li.activeStatus:before,
#progressbar li.activeStatus:after {
  background: var(--confirm-green);
}

#progressbar li.activeStatus:before {
  font-family: FontAwesome;
  content: '\f00c';
}
/* Progression bar Ends */

/* Spinner Classes Ends */
/* Modal Popup */
.modal-popup {
  background-color: #fefefe;
  position: absolute;
  margin: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  border: 1px solid #888;
  width: 500px;
  opacity: 1;
  transition: opacity 1s ease-in-out;
  z-index: 10000;
}

.modal-popup.closed {
  opacity: 0;
}

.btn-info {
  width: 100%;
  background: var(--button-orange);
  color: white;
  padding: 8px;
  border-radius: 3px;
  border: none;
  font-size: 18px;
  transition: opacity 0.2s ease-in-out;
}

.btn-info:hover {
  cursor: pointer;
  opacity: 0.9;
}

.btn-info:active {
  transform: scale(0.9);
  background: var(--dark-blue);
}
/* Modal Popup End*/
/* Modal BanUser Popup */
.hide-ban-user {
  display: none;
}

.modal-ban-user-container {
  position: absolute;
  top: calc(50% - 250px);
  left: calc(50% - 250px);
  background: white;
  height: 500px;
  width: 500px;
  padding: 20px;
}

.modal-ban-user-content {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.align-bottom {
  position: absolute;
  left: 0;
  bottom: 0;
}

.control-title.small {
  font-size: 24px;
}

.ban-user-fields {
  margin-bottom: 5px;
}

.small-input-10 {
  width: 50%;
  margin-right: 5px;
  box-sizing: border-box;
}

.large-input-90 {
  width: 85%;
  box-sizing: border-box;
}

.ban-timer-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.timed-ban-display {
  display: none;
}

.ban-error-message {
  font-size: 12px;
  color: var(--deny-red);
  margin-top: 3px;
  display: none;
}

/* Modal BanUser Popup End*/

.control-title {
  font-weight: normal;
  font-size: 1.8rem;
}

*:focus {
  outline: 0px solid #fff;
  outline-offset: -4px;
}

.footer {
  background: white;
  margin: 0 0 0 280px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: 8px 10px;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  color: #505050;
}

.copyright {
  width: 50%;
}

.copyright-bold {
  font-weight: bold;
}

.version {
  width: 50%;
}

.float-right {
  float: right;
}

.whitesmoke {
  background-image: url('./img/login-bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

/* Width Stuff*/

.w10 {
  width: 10%;
}

.w20 {
  width: 20%;
}

.w30 {
  width: 30%;
}

.w33 {
  width: 33.3333%;
}

.w40 {
  width: 40%;
}

.w50 {
  width: 50%;
}

.w60 {
  width: 60%;
}

.w70 {
  width: 70%;
}

.w80 {
  width: 80%;
}

.w90 {
  width: 90%;
}

.w100 {
  width: 100%;
}

/*Media Queries*/

@media only all and (max-width: 700px) {
  .customer-data-container-50 {
    width: 100%;
  }
}

@media only all and (max-width: 1100px) {
  .customer-data-container {
    flex-direction: column;
  }

  .left-50-container {
    padding: 0 0 5px 0;
  }

  .customer-data-container-50 {
    width: 100%;
  }
}
.baggage-details-header.content {
  border-bottom: none;
}

a.active-trip-card {
  position: relative;
}
.status-text {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  z-index: 1;
}

.assign-drive {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0px;
}
.select-drive {
  display: flex;
  gap: 10px;
  align-items: center;
}
.css-13cymwt-control,
.css-t3ipsp-control {
  width: 400px !important;
  min-height: 47px !important;
}
.content-wrap {
  display: flex;
  justify-content: space-between;
  min-width: 550px;
}

.user-action-button.disabled {
  cursor: not-allowed;
  opacity: 0.5; /* Adjust opacity to indicate the button is disabled */
  /* Add any other styles you want for the disabled state */
}
.custom-select {
  min-width: 350px;
  position: relative;
}

select {
  appearance: none;
  /* safari */
  -webkit-appearance: none;
  /* other styles for aesthetics */
  width: 100%;
  font-size: 1.15rem;
  padding: 0.675em 6em 0.675em 1em;
  background-color: #fff;
  border: 1px solid #caced1;
  border-radius: 0.25rem;
  color: #000;
  cursor: pointer;
}
.custom-select::before,
.custom-select::after {
  --size: 0.3rem;
  position: absolute;
  content: '';
  right: 1rem;
  pointer-events: none;
}

.custom-select::before {
  border-left: var(--size) solid transparent;
  border-right: var(--size) solid transparent;
  border-bottom: var(--size) solid black;
  top: 40%;
}

.custom-select::after {
  border-left: var(--size) solid transparent;
  border-right: var(--size) solid transparent;
  border-top: var(--size) solid black;
  top: 55%;
}
div#tripTypeBtn {
  justify-content: center;
  display: flex;
  align-items: center;
  gap: 5px;
}

.total-value {
  position: absolute;
  right: 20px;
  bottom: 100px;
}
.driver-tag {
  position: absolute;
  top: 5px;
  right: 10px;
}
.delivery-info-card {
  border-bottom: 2px solid #01a2cc;
  padding: 10px 0px;
}

#tripFlightDetails,
.delivery-info {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* .loader,
#loadingSpinner {
  display: block !important;
} */

/* payment-success.css */

.wallet-topup {
  background-color: #f7f7f7;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin: 40px auto;
  width: 80%;
  text-align: center;
}
.loader.show #loadingSpinner.loading-spinner-background {
  display: block;
  position: static;
  background: unset;
}

.loader.show #loadingSpinner.loading-spinner-background .this-that {
  position: relative;
  margin: 50px 0;
}
.wallet-topup p {
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

.wallet-topup p.success {
  color: #2ecc71; /* green color */
}

.wallet-topup p.error {
  color: #e74c3c; /* red color */
}

wallet-topup .btn-success {
  background-color: #4caf50; /* green color */
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.wallet-topup .btn-success:hover {
  background-color: #3e8e41; /* darker green color */
}

.select-box {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0px;
  gap: 10px;
}

.info-company {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  width: 100%;
}
.cancellation-reason {
  color: red !important;
  text-transform: capitalize;
  margin-top: 10px;
}

.data-row.width-100 > .trip-data {
  width: 100% !important;
}

.error-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #fff3f3;
  padding: 20px;
  text-align: center;
}

.error-icon {
  font-size: 64px;
  color: #dc3545;
  margin-bottom: 20px;
}

.error-title {
  font-size: 28px;
  color: #dc3545;
  margin-bottom: 20px;
}

.error-message {
  font-size: 18px;
  color: #555;
  max-width: 600px;
  margin-bottom: 15px;
}

.error-note {
  font-size: 16px;
  color: #666;
  font-style: italic;
  margin-bottom: 20px;
}

.retry-button {
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #dc3545;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.retry-button:hover {
  background-color: #c82333;
}

.verified-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f0f8ff;
  padding: 20px;
  text-align: center;
}

.verified-icon {
  font-size: 64px;
  color: #28a745;
  margin-bottom: 20px;
}

.verified-title {
  font-size: 28px;
  color: #333;
  margin-bottom: 20px;
}

.verified-message {
  font-size: 18px;
  color: #555;
  max-width: 600px;
  margin-bottom: 15px;
}

.verified-email {
  font-weight: bold;
  color: #007bff;
}

.verified-note {
  font-size: 16px;
  color: #666;
  font-style: italic;
}

.urgent {
  position: absolute;
  top: 64px;
  right: 19px;
  font-size: 19px;
  color: black;
}

img.auth-icons {
  max-width: 40px;
  max-height: 40px;
}
table {
  white-space: nowrap;
}
.distance {
  position: absolute;
  bottom: 10px;
  right: 15px;
}
.driver-identifier {
  gap: 10px;
  display: flex;
  font-size: 14px;
  align-items: center;
  color: #01a2cc;
}
input.search-user.form-control {
  height: 50px;
  width: 275xpx;
  outline: none;
  max-width: 500px;
}

.search-user.form-control:focus {
  outline: none;
  border: 0px;
}

.not-editable {
  background-color: #9393935e;
}
.no-edit {
  color: red;
  text-transform: capitalize;
  font-size: 12px;
  margin: 10px;
}

.btn-primary {
  background-color: #1a73e8;
  border-color: #1a73e8;
  height: 2.5rem;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
  background-color: #1765cc;
  border-color: #1765cc;
}
